#root {
    width: 100%;
    height: 100%;
}

body {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.navbar {
    background: linear-gradient(0deg, #E3EDF7, #E3EDF7);
    justify-content: space-between;
}

.navbar .logo-link {
    padding: 0;
}

.navbar .logo-link img {
    width: 32px;
    height: 32px;
}

.navbar .logo-link .logo-title {
    font-weight: 600;
    position: relative;
    top: -0.2rem;
    color: #3D516F;
}

.navbar .navbar-toggler {
    border: 0;
}

.navbar .navbar-toggler:focus {
    outline: none;
}

.navbar .navbar-toggler img {
    width: 50%;
}

.nav-embellish {
    font-size: 72.5%;
    font-weight: 700;
    position: relative;
    top: -0.2rem;
}

.footer-menu {
    z-index: 1001;
}

.footer-menu .nav-link {
    font-size: 1rem;
    font-weight: 700;
}

.footer-menu .nav-link.active {
    color: #3D516F!important;
}

.footer-menu .nav-link img {
    width: 2rem;
    height: 2rem;
}

@media only screen and (max-width: 734px) {
    footer.expanded .footer-menu button.navbar-toggler {
        position: absolute;
    }

    .footer-menu .navbar-nav {
        display: flex;
        flex-direction: row;
    }

    .footer-menu .nav-link {
        flex: 0.25;
    }
}
